// To see this message, follow the instructions for your Ruby framework.
//
// When using a plain API, perhaps it's better to generate an HTML entrypoint
// and link to the scripts and stylesheets, and let Vite transform it.
console.log('Vite ⚡️ Ruby')



// Example: Import a stylesheet in <sourceCodeDir>/index.css
// import '~/index.css'
import '~/stylesheets/main.scss'

import $ from "jquery";
import * as popper from "popper.js";
import * as bootstrap from "bootstrap";


$(document).ready(function () {
  if (window.location.hash.length) {
    var shopName = window.location.hash.substring(1);
    shopSwitch(shopName);
  }
  var lastScrollPos = 0;
  $(window).scroll(function () {
    var currentScrollPos = $(this).scrollTop();
    if (currentScrollPos < lastScrollPos) {
      // scrolling up
      if ($(this).scrollTop() > 20) {
        $(".back-to-top").fadeIn();
      } else {
        $(".back-to-top").fadeOut();
      }
    } else {
      // scrolling down
      $(".back-to-top").fadeOut();
    }
    lastScrollPos = currentScrollPos;
  });
  $(".back-to-top").click(function () {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      800
    );
    return false;
  });
});

$(document).on("click", ".shop-logo", function () {
  var $previouslyActive = $(".active-box");
  var $parentBox = $(this).parent(".shop-box");

  boxSwitcher($previouslyActive, $parentBox);
});

$(document).on("click", ".shop-link", function (event) {
  var shop = $(this).data("shop");
  shopSwitch(shop);
  $(".navbar-collapse").removeClass("show");
});

var shopSwitch = function (shop) {
  $("html, body").animate(
    {
      scrollTop: $("." + shop + "-box").offset().top - 70,
    },
    2000
  );

  let $active = $(".active-box");
  let $newBox = $("." + shop + "-box");
  boxSwitcher($active, $newBox);
};

var boxSwitcher = function (active, newBox) {
  active.removeClass("active-box");
  var clickedLeft = newBox.css("left");
  var clickedZindex = newBox.css("z-index");

  active.css({
    left: clickedLeft,
    zIndex: clickedZindex,
  });

  newBox.removeAttr("style").addClass("active-box");
};

// Scroll Button

var back_to_top_button = '<a href="#" class="back-to-top">⬆️</a>';
$("body").append(back_to_top_button);
$(".back-to-top").hide();